//import React, { useState, useCallback } from "react";
import React from "react";
import ReactPhotoGallery, { Photo } from "react-photo-gallery";
import { CustomGallery, Item } from 'react-photoswipe-gallery'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

const MdxGallery = ({photos, id, layoutRef, className}) => {
  const imageRenderer = ({ index, left, top, key, photo }) => {
    return ( <Item
        title={photo.title}
        id={index}
        key={key}
        original={photo.src}
        thumbnail={photo.src}
        width={photo.origWidth}
        height={photo.origHeight}
      >
      {
        ({ ref, open }) => {
          photo.ref = ref
          return (
            <Photo
              index={index}
              onClick={open}
              photo={photo}
              top={top}
              left={left}
              key={key}
            />
          )
        }
      }
      </Item>
    )
  }

  return (
    <div className={className + " gallery"}>
      <CustomGallery ui={PhotoswipeUIDefault} id={id} layoutRef={layoutRef} className="space-y-0">
        <ReactPhotoGallery photos={photos} renderImage={imageRenderer}/>
      </CustomGallery>
    </div>
  )
}

export default MdxGallery