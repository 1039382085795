import React, {useMemo, useRef} from "react"
import { Link, graphql } from "gatsby"
import { getSrc, getSrcSet, StaticImage, GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import MdxGallery from "../components/gallery"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { DefaultLayout } from "react-photoswipe-gallery"

const shortcodes = { StaticImage, GatsbyImage }

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const { previous, next } = data
  const layoutRef = useRef()

  const allComponents = useMemo(() => {
    const GalleryComponent = ({ id, ...props }) => {
      const gallery = data.mdx.frontmatter.galleries.find(gallery => gallery.id === id)
      const galleryMap = gallery.images.map( image => {
        return {
          srcSet: getSrcSet(image.src),
          src: getSrc(image.src),
          width: image.src.childImageSharp.original.width,
          height: image.src.childImageSharp.original.height,
          origWidth: image.src.childImageSharp.original.width,
          origHeight: image.src.childImageSharp.original.height,
          className: "p-1",
          title: image.title,
        }
      })
      return <MdxGallery
        id={id}
        photos={galleryMap}
        layoutRef={layoutRef}
        {...props}
      />
    }

    const GalleryLayout = () => {
      return <DefaultLayout
        shareButton={true}
        fullscreenButton={true}
        zoomButton={false}
        ref={layoutRef}
      />
    }
 
    const Wrapper = props => (
      <div>
        <main {...props} />
        <GalleryLayout/>
      </div>
    )

    return {
      ...shortcodes,
      Gallery: GalleryComponent,
      GalleryLayout: GalleryLayout,
      wrapper: Wrapper,
    }
  }, [data])

  return (
    <Layout
      location={location}
      title={post.frontmatter.title}
      subtitle={post.frontmatter.subtitle}
      image={post.frontmatter.image.childImageSharp}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post prose-lg full-read"
        itemScope
        itemType="http://schema.org/Article"
      >
        <section>
          <MDXProvider components={allComponents}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
        </section>
        <hr />
        <footer>
          
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
  site {
    siteMetadata {
      title
    }
  }
  mdx(id: {eq: $id}) {
    id
    excerpt(pruneLength: 160)
    body
    frontmatter {
      title
      subtitle
      date(formatString: "MMMM DD, YYYY")
      description
      tags
      image {
        childImageSharp {
          gatsbyImageData(
            quality: 60,
            layout: FULL_WIDTH,
            formats: [AUTO, WEBP]
          )
        }
      }
      galleries {
        id
        images {
          title
          src {
            childImageSharp {
              gatsbyImageData(
                quality: 60,
                layout: FULL_WIDTH,
                formats: WEBP,
                breakpoints: [750, 1080, 1200],
                webpOptions: {
                  quality: 60
                }
              )
              original {
                width
                height
              }
            }
          }
        }
      }
    }
  }
  previous: mdx(id: {eq: $previousPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
  next: mdx(id: {eq: $nextPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
}
`
